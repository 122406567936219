<template>
  <div class="profile-page">
    <div class="wrap-title">
      <div class="back" @click="back"> Назад</div>
      <div class="title">Политика конфиденциальности и обработки персональных данных</div>
    </div>
    <div class="body">
      Настоящая оферта является публичным предложением от ТОО «Тайм Трекер» (БИН 231240011993), именуемое в дальнейшем «Лицензиар», в лице Директора Скальского Д.Н., действующего на основании Устава, к любому лицу, именуемому в дальнейшем «Лицензиат», которое примет (акцептует) ее целиком и безусловно с намерением приобрести у Лицензиара лицензию, указанную в настоящем документе, посредством присоединения к условиям оферты через оплату первого платежа, подтверждение на сайте и иные действия, явно свидетельствующие о принятии оферты. Лицензиар и Лицензиат далее совместно именуются «Стороны», а оферта, акцептованная Лицензиатом – «Договор».<br>
      <br>
      1. Общие положения<br>
      <br>
      1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.<br>
      1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о своих клиентах, а также посетителях своего сайта https://timetracker.kz и мобильного приложения «TTracker» (далее совместно и по отдельности именуемым «Ресурс»).<br>
      2. Основные понятия, используемые в Политике<br>
      <br>
      2.1 Администратор собирает и хранит только те Персональные данные, которые необходимы для оказания Услуг Администратором и взаимодействия с Пользователем.<br>
      2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.<br>
      2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).<br>
      2.3. Информационная система персональных данных – совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.<br>
      2.4. Обезличивание персональных данных – действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.<br>
      2.5. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br>
      2.6. Оператор – лицо, самостоятельно или совместно с другими лицами организующее и/или осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br>
      2.7. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю Ресурса.<br>
      2.8. Персональные данные, разрешенные субъектом персональных данных для распространения, – персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее – персональные данные, разрешенные для распространения).<br>
      2.9. Пользователь – любой посетитель Ресурса.<br>
      2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.<br>
      2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.<br>
      2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.<br>
      2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.<br>
      3. Основные права и обязанности Оператора<br>
      <br>
      3.1. Оператор имеет право:<br>
      <br>
      3.1.1. получать от Пользователя достоверные информацию и/или документы, содержащие персональные данные;<br>
      3.1.2. в случае отзыва Пользователем согласия на обработку персональных данных, а также направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в Законе о персональных данных;<br>
      3.1.3. самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими законами РК.<br>
      3.2. Оператор обязан:<br>
      <br>
      3.2.1. предоставлять Пользователю по его просьбе информацию, касающуюся обработки его персональных данных;<br>
      3.2.2. организовывать обработку персональных данных в порядке, установленном действующим законодательством;<br>
      3.2.3. отвечать на обращения и запросы Пользователей и их законных представителей в соответствии с требованиями Закона о персональных данных;<br>
      3.2.4. сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;<br>
      3.2.5. публиковать или иным образом обеспечивать неограниченный доступ к настоящей политике в отношении обработки персональных данных;<br>
      3.2.6. принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;<br>
      3.2.7. прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;<br>
      3.2.8. исполнять иные обязанности, предусмотренные Законом о персональных данных.<br>
      4. Основные права и обязанности Пользователя<br>
      <br>
      4.1. Пользователь имеют право:<br>
      4.1.1. получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных законами. Сведения предоставляются Пользователю Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;<br>
      4.1.2. требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;<br>
      4.1.3. выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;<br>
      4.1.4. на отзыв согласия на обработку персональных данных, а также на направление требования о прекращении обработки персональных данных;<br>
      4.1.5. обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;<br>
      4.1.6. на осуществление иных прав, предусмотренных законодательством РК.<br>
      5. Принципы обработки персональных данных<br>
      <br>
      5.1. Обработка персональных данных осуществляется на законной и справедливой основе.<br>
      5.2. Обработка персональных данных ограничивается достижением Оператором конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.<br>
      5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.<br>
      5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.<br>
      5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.<br>
      5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных и неточных данных.<br>
      6. Цели обработки персональных данных<br>
      <br>
      Цель обработки	• Заключение, исполнение и прекращение гражданско-правовых договоров<br>
      Персональные данные	• Фамилия, имя, отчество<br>
      • Адрес электронной почты<br>
      • Номера телефонов<br>
      Правовые основания	• Договоры, заключаемые между Оператором и Пользователем<br>
      Виды обработки персональных данных	• Сбор, запись, систематизация, накопление, хранение, уничтожение и обезличивание персональных данных<br>
      • Отправка информационных писем на адрес электронной почты<br>
      7. Условия обработки персональных данных<br>

      7.1. Обработка персональных данных осуществляется с согласия Пользователя как субъекта персональных данных на обработку его персональных данных.<br>
      7.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Республики Казахстан или законом, для осуществления возложенных законодательством Республики Казахстан на Оператора функций, полномочий и обязанностей.<br>
      7.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Республики Казахстан об исполнительном производстве.<br>
      7.4. Обработка персональных данных необходима для исполнения договора, стороной которого является Пользователь, а также для заключения договора по инициативе Пользователя.<br>
      7.5. Обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Пользователя.<br>
      7.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен Пользователем либо по его просьбе (далее – общедоступные персональные данные).<br>
      7.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с законом.<br>
      8. Порядок сбора, хранения, передачи и других видов обработки персональных данных<br>
      <br>
      8.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.<br>
      8.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.<br>
      8.3. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если Пользователем дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.<br>
      8.4. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора support@timetracker.kz с пометкой «Актуализация персональных данных».<br>
      8.5. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора support@timetracker.kz с пометкой «Отзыв согласия на обработку персональных данных».<br>
      8.6. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.<br>
      8.7. Установленные Пользователем запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РК.<br>
      8.8. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.<br>
      8.9. Оператор осуществляет хранение персональных данных в форме, позволяющей определить Пользователя, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен законом, договором, стороной которого является Пользователь.<br>
      8.10. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия Пользователя, отзыв согласия субъектом персональных данных или требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных данных.<br>
      9. Перечень действий, производимых Оператором с полученными персональными данными<br>
      <br>
      9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.<br>
      9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.<br>
      10. Конфиденциальность персональных данных<br>
      <br>
      10.1. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено законом.<br>
      11. Заключительные положения<br>
      <br>
      11.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты support@timetracker.kz.<br>
      11.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.<br>
      11.3. Актуальная версия Политики в свободном доступе расположена на Ресурсе.<br>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageIndex',
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'register', })
    },
  },
}
</script>

<style scoped lang="scss">
.profile-page {
  display: flex;
  flex-direction: column;
  .body {
    margin-top: 10px;
    padding: 15px;
    text-align: justify;
  }
  .wrap-title{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .back {
      position: absolute;
      top: calc(env(safe-area-inset-top) + 10px);
      left: 14px;
      color: var(--primary);
    }
    padding-top: calc( env(safe-area-inset-top) + 20px);
    padding-bottom: 5px;
  }

  .title {
    margin-top: 20px;
    text-align: center;
    color: black;
    font-size: 15px;
    font-weight: 700;
  }
  .navigation {
    margin-top: 20px;
    .item {
      .left-side {
        display: flex;
        align-items: center;
        font-weight: bold;
        .n-icon {
          margin-right: 10px;
        }
      }
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .avatar {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;
    }

    .name{
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .user-data{
    .item{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }

      .label{
        color: #959494;
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 10px;
        height: 40px;
        background-color: #F5F5FA;
        box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(170, 170, 204, 0.25),
        10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;
        border-radius: 16px;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    padding: 0 10px;
    .profile-button {
      color: #686868;
      &.danger {
        color: #FF4B33;
      }
    }
  }
}
</style>
